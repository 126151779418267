import type { SVGProps } from "./types";

export const LogoutRightUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13.8889 13.1111L17 10M17 10L13.8889 6.8889M17 10L6.11111 10M10.7778 13.1111V13.8889C10.7778 15.1776 9.73311 16.2222 8.44444 16.2222H5.33333C4.04467 16.2222 3 15.1776 3 13.8889V6.11112C3 4.82246 4.04467 3.77779 5.33333 3.77779H8.44444C9.73311 3.77779 10.7778 4.82246 10.7778 6.11112V6.8889"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
